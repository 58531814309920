import { createApp } from 'vue'
import App from './App.vue'

import { vMaska } from "maska"


import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

createApp(App).directive("maska", vMaska).use(VueTelInput).mount('#app')
