<template>
  <div class="b-intro-block__form">
    <div class="b-intro-block__form-inputs">
      <input
        type="hidden"
        class="js-form-name-input"
        name="form_name"
        value="transfer_form"
      />
      <div class="b-intro-block__input u-input u-input_with-select">
        <label
          class="b-intro-block__input-label u-input__label"
          for="from_fiat_sum"
        >
          Хочу отправить
        </label>
        <div class="u-input__input-area">
          <div class="u-input__input-container">
            <div
              class="
                u-select
                u-select_currency
                u-select_selected-right
                u-select_hide-searcher
              "
              id="from_fiat"
            >
              <Select2
                v-model="ddata.currency_in"
                :options="this.currency_in"
                :settings="{ templateSelection: select2SelectedCurrencyFormat }"
              />
            </div>
            <input
              v-model="ddata.transAmount"
              class="u-input__input js-from_fiat_sum-input"
              placeholder="Введите сумму"
              type="text"
            />
          </div>
          <span
            class="u-input__input-error u-error js-from_fiat_sum-error"
            style="display: inline"
            >{{ maxMinErrorText }} {{ currencyInErrorText }}</span
          >
        </div>
      </div>
      <div class="b-intro-block__input u-input u-input_with-select">
        <label
          class="b-intro-block__input-label u-input__label"
          for="to_fiat_sum"
        >
          Получу
        </label>
        <div class="u-input__input-area">
          <div class="u-input__input-container">
            <div
              class="
                u-select
                u-select_currency
                u-select_selected-right
                u-select_hide-searcher
              "
              id="to_fiat"
            >
              <Select2
                v-model="ddata.currency_out"
                :options="this.currency_out"
                :settings="{ templateSelection: select2SelectedCurrencyFormat }"
              />
            </div>
            <input
              class="u-input__input js-to_fiat_sum-input"
              id="to_fiat_sum"
              type="text"
              disabled
              name="to_fiat_sum"
              v-model="ddata.transAmountOut"
            />
          </div>
          <span
            class="u-input__input-error u-error js-to_fiat_sum-error"
            style="display: inline"
            >{{ currencyOutErrorText }}</span
          >
        </div>
      </div>
      <div class="b-intro-block__input u-input">
        <label
          class="b-intro-block__input-label u-input__label"
          for="from_trade_method"
          >Банк отправителя</label
        >
        <div class="u-input__input-area">
          <div class="u-input__input-container">
            <div class="u-select" id="from_trade_method">
              <Select2
                v-model="ddata.bank_in"
                :options="this.bank_in"
                :settings="{ templateSelection: select2SelectedBankFormat }"
              />
            </div>
          </div>
          <span
            class="u-input__input-error u-error js-from_trade_method-error"
            style="display: inline"
            >{{ bankInErrorText }}</span
          >
        </div>
      </div>
      <div class="b-intro-block__input u-input">
        <label
          class="b-intro-block__input-label u-input__label"
          for="to_trade_method"
          >Банк получателя</label
        >
        <div class="u-input__input-area">
          <div class="u-input__input-container">
            <div class="u-select" id="to_trade_method">
              <Select2
                v-model="ddata.bank_out"
                :options="this.bank_out"
                :settings="{ templateSelection: select2SelectedBankFormat }"
              />
            </div>
          </div>
          <span
            class="u-input__input-error u-error js-to_trade_method-error"
            style="display: inline"
            >{{ bankOutErrorText }}</span
          >
        </div>
      </div>
      <div class="b-intro-block__input u-input">
        <label class="b-intro-block__input-label u-input__label" for="phone"
          >Ваш номер телефона</label
        >
        <div class="u-input__input-area">
          <div class="u-input__input-container">
            <MazPhoneNumberInput
              v-model="phoneNumber"
              show-code-on-list
              color="info"
              :preferred-countries="['FR', 'BE', 'DE', 'US', 'GB']"
              :ignored-countries="['AC']"
              @update="phoneResults = $event"
              :success="phoneResults?.isValid"
              :translations="{
                countrySelector: {
                  placeholder: '',
                  error: 'Выбрать страну',
                },
                phoneInput: {
                  placeholder: 'Верно',
                  example: '',
                },
              }"
            />
          </div>
          <span
            class="u-input__input-error u-error js-phone-error"
            style="display: inline"
            >{{ phoneErrorText }}</span
          >
        </div>
      </div>

      <div class="b-intro-block__form-info-container">
        <div class="b-intro-block__rate-label">
          <img src="assets/images/b-intro-block/round-arrows.svg" alt="rate" />
          <span class="b-intro-block__rate-label-text"
            >Курс: <span v-html="renderCourse"></span
          ></span>
        </div>
        <div class="b-intro-block__time">
          <img src="assets/images/b-intro-block/round-time.svg" alt="rate" />
          <span class="b-intro-block__time-text">Время перевода: 15 мин.</span>
        </div>
      </div>
    </div>
    <div class="b-intro-block__form-footer">
      <div class="b-intro-block__result-container"></div>
      <button
        v-on:click="showCardsPopUp()"
        class="b-intro-block__form-button u-button"
      >
        <span class="u-button__content">Отправить заявку</span>
      </button>
      <div class="b-intro-block__agreement">
        Нажимая кнопку, вы соглашаетесь
        <span class="u-ws-nowrap"
          >с
          <a class="u-link u-link_usual" href="terms.html"
            >условиями сервиса</a
          ></span
        >
      </div>
    </div>
  </div>

  <!-- <h4>Value: {{ myValue }}</h4>
  <h4>ddata: {{ ddata }}</h4> -->

  <div class="u-popup-roott" v-bind:class="{ flex: this.isRootPopUpWrap }">
    <div
      class="u-overlay"
      v-bind:class="{ 'u-overlay_visible': this.isOverLay }"
      v-on:click="hideOverLay()"
    ></div>

    <div
      class="b-card-popup u-popup"
      v-bind:class="{ flex: this.isShowCardPopUp }"
      id="card-popup"
    >
      <div class="u-popup__close-btn js-popup-close-btn js-hide-overlay">
        <img
          class="u-popup__close-btn-icon"
          v-on:click="hideCardpopUp()"
          src="assets/images/u-popup/close-button.svg"
          alt="close-button"
        />
      </div>
      <div class="b-card-popup__form">
        <div class="b-card-popup__form-inputs">
          <input
            type="hidden"
            class="js-form-name-input"
            name="form_name"
            value="transfer_form"
          />
          <div class="b-card-popup__input u-input">
            <label
              class="b-card-popup__label u-input__label js-from_card-label"
              for="from_card"
              >Номер карты отправителя</label
            >
            <div class="u-input__input-area">
              <div class="u-input__input-container">
                <input
                  class="u-input__input js-from_card-input"
                  id="from_card"
                  placeholder="Введите номер карты"
                  type="text"
                  name="from_card"
                  v-model="card_number_in"
                  v-maska
                  data-maska="####-####-####-####"
                  maxlength="64"
                />
              </div>

              <span
                class="u-input__input-error u-error js-from_card-error"
                style="display: inline"
                >{{ cardNumberInErrorText }}</span
              >
            </div>
          </div>
          <div class="b-card-popup__input u-input">
            <label class="b-card-popup__label u-input__label" for="from_name"
              >Имя Фамилия карты отправителя</label
            >
            <div class="u-input__input-area">
              <div class="u-input__input-container">
                <input
                  class="u-input__input js-from_name-input"
                  id="from_name"
                  placeholder="Введите имя и фамилию"
                  type="text"
                  name="from_name"
                  v-model="card_name_in"
                  maxlength="64"
                />
              </div>
              <span
                class="u-input__input-error u-error js-from_name-error"
                style="display: inline"
                >{{ cardNameInErrorText }}</span
              >
            </div>
          </div>
          <div class="b-card-popup__input u-input">
            <label
              class="b-card-popup__label u-input__label js-to_card-label"
              for="to_card"
              >Номер карты получателя</label
            >
            <div class="u-input__input-area">
              <div class="u-input__input-container">
                <input
                  class="u-input__input js-to_card-input"
                  id="to_card"
                  placeholder="Введите номер карты"
                  type="text"
                  name="to_card"
                  v-model="card_number_out"
                  v-maska
                  data-maska="####-####-####-####"
                  maxlength="64"
                />
              </div>
              <span
                class="u-input__input-error u-error js-to_card-error"
                style="display: inline"
                >{{ cardNumberOutErrorText }}</span
              >
            </div>
          </div>
          <div class="b-card-popup__input u-input">
            <label class="b-card-popup__label u-input__label" for="to_name"
              >Имя Фамилия получателя</label
            >
            <div class="u-input__input-area">
              <div class="u-input__input-container">
                <input
                  class="u-input__input js-to_name-input"
                  id="to_name"
                  placeholder="Введите имя и фамилию"
                  type="text"
                  name="to_name"
                  v-model="card_name_out"
                  maxlength="64"
                />
              </div>
              <span
                class="u-input__input-error u-error js-to_name-error"
                style="display: inline"
                >{{ cardNameOutErrorText }}</span
              >
            </div>
          </div>
        </div>
        <div class="b-card-popup__result-container"></div>
        <button
          href="#"
          class="b-card-popup__form-button u-button"
          v-on:click="sendInTg()"
        >
          <span class="u-button__content">Отправить заявку</span>
        </button>
        <div class="b-card-popup__agreement">
          Нажимая кнопку, вы соглашаетесь
          <span class="u-ws-nowrap"
            >с
            <a class="u-link u-link_usual" href="terms.html"
              >условиями сервиса</a
            ></span
          >
        </div>
      </div>
    </div>

    <div
      class="b-application-success-popup u-popup"
      id="application-success"
      v-bind:class="{ flex: this.isShowSuccessPopUp }"
    >
      <div
        class="u-popup__close-btn js-popup-close-btn js-hide-overlay"
        v-on:click="hideSuccessPopUp()"
      >
        <img
          class="u-popup__close-btn-icon"
          src="assets/images/u-popup/close-button.svg"
          alt="close-button"
        />
      </div>
      <div class="b-application-success-popup__inner">
        <div class="b-application-success-popup__title">
          Теперь выберите удобный мессенджер для продолжения перевода
        </div>
        <div class="b-application-success-popup__socials">
          <a
            class="b-whatsapp-button b-application-success-popup__social-button"
            target="_blank"
            href="https://api.whatsapp.com/send?phone=79587618427&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D1%81%D0%BE%D0%B7%D0%B4%D0%B0%D0%BD%D0%B0%20%D0%B7%D0%B0%D1%8F%D0%B2%D0%BA%D0%B0%20%D0%BD%D0%B0%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B2%D0%BE%D0%B4"
          >
            WhatsApp
            <img
              class="b-whatsapp-button__social-icon"
              src="assets/images/whatsapp-icon.svg"
              alt="whatsapp"
            />
          </a>
          <a
            class="b-telegram-button b-application-success-popup__social-button"
            target="_blank"
            href="https://t.me/nummachat"
          >
            Telegram
            <img
              class="b-telegram-button__social-icon"
              src="assets/images/tm-icon.svg"
              alt="telegram"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { ref } from "vue";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import "maz-ui/css/main.css";
import Select2 from "vue3-select2-component";

const phoneNumber = ref();
const phoneResults = ref();

import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      // Дебаунсер
      debouncerStatus: true,
      resultObj: null,
      loading: false,
      isOverLay: false,

      isRootPopUpWrap: false,
      isShowCardPopUp: false,
      isShowSuccessPopUp: false,

      maxMinErrorStatus: false,
      maxMinErrorText: "",

      phoneErrorText: "",
      currencyInErrorText: "",
      currencyOutErrorText: "",
      bankInErrorText: "",
      bankOutErrorText: "",

      request: "",
      apiUrl: "https://paypairing.com/bapi/api.php?type=",
      tgApiUrl: "https://paypairing.com/bapi/tg.php",
      bank_in: [],
      bank_out: [],
      currency_in: [],
      currency_out: [],

      phoneNumber,
      phoneResults,
      ddata: {
        currency_in: null,
        currency_out: null,
        bank_in: null,
        bank_out: null,
        transAmount: 0,
        transAmountOut: 0,
      },
      data_currency_in: null,
      data_currency_out: null,

      //

      cardNumber: null,
      options: {
        creditCard: true,
        delimiter: "-",
        creditCardStrictMode: false,
        noImmediatePrefix: true,
      },

      card_name_in: "",
      card_number_in: "",
      card_name_out: "",
      card_number_out: "",

      cardNameInErrorText: "",
      cardNumberInErrorText: "",
      cardNameOutErrorText: "",
      cardNumberOutErrorText: "",
    };
  },
  components: {
    MazPhoneNumberInput,
    Select2,
  },

  mounted() {
    this.getapi("get_currency_in");
    this.getapi("get_currency_out");

    let cur_in = document.getElementById("data_cur_in");
    this.ddata.currency_in = cur_in.value;

    let cur_out = document.getElementById("data_cur_out");
    this.ddata.currency_out = cur_out.value;

    let bank_in = document.getElementById("data_bank_in");
    this.ddata.bank_in = bank_in.value;

    let bank_out = document.getElementById("data_bank_out");
    this.ddata.bank_out = bank_out.value;

    let start_sum = document.getElementById("data_start_sum");
    this.ddata.transAmount = parseInt(start_sum.value);

    // let default_curr_in = document.getElementsByClassName('data_cur_in').cur_in;
    // alert(default_curr_in);
  },

  methods: {
    sendInTgLight() {
 
      let textTmp = "";
      textTmp += "Телефон : " + this.phoneResults.formatInternational + "\n";

      textTmp += "------------------------------------------\n";
      textTmp += "Валюта входа : " + this.ddata.currency_in + "\n";
      textTmp += "Банк входа : " + this.ddata.bank_in + "\n";
      textTmp +=
        "Сумма : " +
        this.ddata.transAmount +
        " " +
        this.ddata.currency_in +
        "\n";
      textTmp += "------------------------------------------\n";
      textTmp += "Валюта выхода : " + this.ddata.currency_out + "\n";
      textTmp += "Банк выхода : " + this.ddata.bank_out + "\n";

      if (typeof this.resultObj.outCourse !== "undefined") {
        textTmp +=
          "Курс : " +
          "1 " +
          this.ddata.currency_in +
          " = " +
          this.resultObj.outCourse +
          " " +
          this.ddata.currency_out +
          "\n";
      }

      textTmp +=
        "Сумма : " +
        this.ddata.transAmountOut +
        " " +
        this.ddata.currency_out +
        "\n";
      textTmp += "------------------------------------------\n";

      const text = { text_data: textTmp };
      axios
        .post(this.tgApiUrl, text)
        .then((response) => {
          console.log(response);
     
          this.isRootPopUpWrap = true;
          this.isOverLay = true;
          this.isShowSuccessPopUp = true;

          window.ym(92263528,'reachGoal','lead');

        })
        .catch((error) => {
          console.log(error);
        });
    },

    sendInTg() {
      if (!this.card_name_in) {
        this.cardNameInErrorText = "Необходима заполнить имя отправителя!";
      } else {
        this.cardNameInErrorText = "";
      }

      if (!this.card_number_in) {
        this.cardNumberInErrorText =
          "Необходима заполнить номер карты отправителя!";
      } else {
        this.cardNumberInErrorText = "";
      }

      if (!this.card_name_out) {
        this.cardNameOutErrorText = "Необходима заполнить имя получателя!";
      } else {
        this.cardNameOutErrorText = "";
      }

      if (!this.card_number_out) {
        this.cardNumberOutErrorText =
          "Необходима заполнить номер карты получателя!";
      } else {
        this.cardNumberOutErrorText = "";
      }

      if (
        !this.card_name_in ||
        !this.card_number_in ||
        !this.card_name_out ||
        !this.card_number_out
      ) {
        return false;
      }

      let textTmp = "";
      textTmp += "Телефон : " + this.phoneResults.formatInternational + "\n";

      textTmp += "------------------------------------------\n";
      textTmp += "Валюта входа : " + this.ddata.currency_in + "\n";
      textTmp += "Банк входа : " + this.ddata.bank_in + "\n";
      textTmp += "Карта отправителя : " + this.card_number_in + "\n";
      textTmp += "Имя на карте отправителя : " + this.card_name_in + "\n";
      textTmp +=
        "Сумма : " +
        this.ddata.transAmount +
        " " +
        this.ddata.currency_in +
        "\n";
      textTmp += "------------------------------------------\n";
      textTmp += "Валюта выхода : " + this.ddata.currency_out + "\n";
      textTmp += "Банк выхода : " + this.ddata.bank_out + "\n";
      textTmp += "Карта получателя : " + this.card_number_out + "\n";
      textTmp += "Имя на карте получателя : " + this.card_name_out + "\n";

      if (typeof this.resultObj.outCourse !== "undefined") {
        textTmp +=
          "Курс : " +
          "1 " +
          this.ddata.currency_in +
          " = " +
          this.resultObj.outCourse +
          " " +
          this.ddata.currency_out +
          "\n";
      }

      textTmp +=
        "Сумма : " +
        this.ddata.transAmountOut +
        " " +
        this.ddata.currency_out +
        "\n";
      textTmp += "------------------------------------------\n";

      const text = { text_data: textTmp };
      axios
        .post(this.tgApiUrl, text)
        .then((response) => {
          console.log(response);

          this.isShowCardPopUp = false;
          this.isShowSuccessPopUp = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    showCardsPopUp() {
      if (!this.ddata.currency_in) {
        this.currencyInErrorText = "Необходима выбрать валюту!";
      } else {
        this.currencyInErrorText = "";
      }

      if (!this.ddata.currency_out) {
        this.currencyOutErrorText = "Необходима выбрать валюту!";
      } else {
        this.currencyOutErrorText = "";
      }

      if (!this.ddata.bank_in) {
        this.bankInErrorText = "Необходима выбрать банк!";
      } else {
        this.bankInErrorText = "";
      }

      if (!this.ddata.bank_out) {
        this.bankOutErrorText = "Необходима выбрать банк!";
      } else {
        this.bankOutErrorText = "";
      }

      if (!this.phoneResults.isValid) {
        this.phoneErrorText = "Заполните телефонный номер корректно!";
      } else {
        this.phoneErrorText = "";
      }

      this.maxMinErrorStatus = false;
      if (this.currency_in && this.ddata.currency_in) {
        let _this = this;
        this.currency_in.forEach(function (elem) {
          if (elem.id == _this.ddata.currency_in) {
            if (parseInt(_this.ddata.transAmount) < parseInt(elem.min)) {
              _this.maxMinErrorStatus = true;
              _this.maxMinErrorText =
                "Установлено минимально возможное значение, укажите сумму от " +
                elem.min +
                " до " +
                elem.max;
              _this.ddata.transAmount = elem.min;
            }
            if (parseInt(_this.ddata.transAmount) > parseInt(elem.max)) {
              _this.maxMinErrorStatus = true;
              _this.maxMinErrorText =
                "Установлено максимально возможное значение, укажите сумму от " +
                elem.min +
                " до " +
                elem.max;
              _this.ddata.transAmount = elem.max;
            }
          }
        });
      }
      if (!this.maxMinErrorStatus) {
        this.maxMinErrorText = "";
      }

      if (
        !this.ddata.bank_out ||
        !this.ddata.bank_in ||
        !this.ddata.currency_out ||
        !this.ddata.currency_in ||
        !this.phoneResults.isValid ||
        this.maxMinErrorStatus
      ) {
        return false;
      }

      // this.isRootPopUpWrap = true;
      // this.isShowCardPopUp = true;
      // this.isOverLay = true;
      this.sendInTgLight();

      return false;
    },

    hideCardpopUp() {
      this.isRootPopUpWrap = false;
      this.isShowCardPopUp = false;
      this.isOverLay = false;
      return false;
    },

    hideSuccessPopUp() {
      this.isRootPopUpWrap = false;
      this.isShowSuccessPopUp = false;
      this.isOverLay = false;
      return false;
    },

    hideOverLay() {
      this.isRootPopUpWrap = false;
      this.isShowSuccessPopUp = false;
      this.isShowCardPopUp = false;
      this.isOverLay = false;
      return false;
    },

    getapi(request, currency_in = null, currency_out = null) {
      let url = "";
      if (currency_in) {
        url = this.apiUrl + request + "&currency_in=" + currency_in;
      } else if (currency_out) {
        url = this.apiUrl + request + "&currency_out=" + currency_out;
      } else {
        url = this.apiUrl + request;
      }

      axios.get(url).then((response) => {
        if (request == "get_currency_in") {
          this.currency_in = response.data;
        } else if (request == "get_currency_out") {
          this.currency_out = response.data;
        } else if (request == "get_bank_in") {
          this.bank_in = response.data;
        } else if (request == "get_bank_out") {
          this.bank_out = response.data;
        }
      });
    },

    select2SelectedCurrencyFormat(data) {
      if (data) {
        return data.id;
      } else {
        return 12;
      }
    },

    select2SelectedBankFormat(data) {
      if (data) {
        return data.text;
      } else {
        return 12;
      }
    },

    getApiResult(request) {
      let url = "";
      if (request == "calculate") {
        url = this.apiUrl + request;
      }
      if (
        this.ddata.currency_in &&
        this.ddata.currency_out &&
        this.ddata.bank_in &&
        this.ddata.bank_out &&
        this.ddata.transAmount
      ) {
        url +=
          "&payTypes_in=" +
          this.ddata.bank_in +
          "&fiat_in=" +
          this.ddata.currency_in +
          "&payTypes_out=" +
          this.ddata.bank_out +
          "&fiat_out=" +
          this.ddata.currency_out +
          "&transAmount=" +
          this.ddata.transAmount;

        this.loading = true;
        axios.get(url).then((response) => {
          this.resultObj = response.data;
          this.ddata.transAmountOut = this.resultObj.outSumm;
          this.loading = false;
        });
      }
    },

    checkBaxMinSumm() {
      // this.maxMinErrorStatus = false;
      // if (this.currency_in && this.ddata.currency_in) {
      //   let _this = this;
      //   this.currency_in.forEach(function (elem) {
      //     if (elem.id == _this.ddata.currency_in) {
      //       if (parseInt(_this.ddata.transAmount) < parseInt(elem.min)) {
      //         _this.maxMinErrorStatus = true;
      //         _this.maxMinErrorText =
      //           "Укажите сумму от " + elem.min + " до " + elem.max;
      //         _this.ddata.transAmount = elem.min;
      //       }
      //       if (parseInt(_this.ddata.transAmount) > parseInt(elem.max)) {
      //         _this.maxMinErrorStatus = true;
      //         _this.maxMinErrorText =
      //           "Укажите сумму от " + elem.min + " до " + elem.max;
      //         _this.ddata.transAmount = elem.max;
      //       }
      //     }
      //   });
      // }
    },
  },

  watch: {
    // Подгружаем валюты входа
    "ddata.currency_in"(currency_in) {
      this.getapi("get_bank_in", currency_in, null);
      this.getApiResult("calculate");
      this.checkBaxMinSumm();
    },

    // Подгружаем валюты выхода
    "ddata.currency_out"(currency_out) {
      this.getapi("get_bank_out", null, currency_out);
      this.getApiResult("calculate");
    },

    "ddata.bank_in"() {
      this.getApiResult("calculate");
    },

    "ddata.bank_out"() {
      this.getApiResult("calculate");
    },

    "ddata.transAmount"() {
      this.checkBaxMinSumm();

      // Debouncer START
      this.debouncerStatus = true;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(
        () => {
          // Формируем общий запрос
          this.getApiResult("calculate");
        },
        this.debouncerStatus ? 300 : 0
      );
      // Debouncer END
    },
  },

  computed: {
    renderCourse() {
      if (this.loading) {
        return "<img class='loadin_cource' src='assets/images/loading.gif' alt='Loading...'>";
      } else {
        if (this.resultObj && typeof this.resultObj.outCourse !== "undefined") {
          return (
            "1 " +
            this.ddata.currency_out +
            " = " +
            this.resultObj.outCourse +
            " " +
            this.ddata.currency_in
          );
        } else return "";
      }
    },
  },
};
</script>

<style>
.m-phone-number-input .m-phone-number-input__country-flag {
  left: 0 !important;
  bottom: 18px !important;
}

.m-phone-number-input:not(.--no-flags)
  .m-phone-number-input__select
  .m-input-wrapper
  .m-select-input {
  margin-left: -20px;
  padding-left: 25px !important;
  width: 75px !important;
}

.m-phone-number-input__select .m-select-input .m-input-wrapper {
  border: none;
}

.m-phone-number-input__input.--border-radius .m-input-wrapper {
  border: none;
}

.m-phone-number-input__select {
  width: 75px;
}

.m-phone-number-input__select {
  width: 75px !important;
}

.m-input.--has-label .m-input-input {
  padding-left: 10px !important;
  padding-right: 0 !important;
  padding-top: 1rem;
}

.m-input.--md .m-input-input,
.m-input.--md .m-input-label {
  font-size: 12px !important;
  left: 5px;
}

.m-input-wrapper-right {
  padding-right: 0px !important;
}

.select2-container--default .select2-selection--single {
  border: none;
}

.loadin_cource {
  height: 15px;
  margin-left: 10px;
  width: 15px;
}

.flex {
  display: flex !important;
}

.u-popup-roott {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  display: none;
  justify-content: center;
  align-items: center;
}

#app .u-popup {
  z-index: 999999999;
}
</style>
